/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "~ng-zorro-antd/ng-zorro-antd.variable.min.css";
@import "~ng-zorro-antd/ng-zorro-antd.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

$primaryColor: "#FBBC05";
$secondaryColor: "#6c757d";
$theme-colors: (
  "primary": #f95031,
  "secondary": #6c757d,
);

@import "bootstrap/scss/bootstrap";
$popover-arrow-bg: red;

html,
body {
  height: 100%;
}
::-webkit-scrollbar {
  // display: none;
}
* {
  font-family: "Poppins", sans-serif;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f8f9fa !important;
  font-size: 14px;
}

:root {
  --ant-primary-color: #f3c130 !important; /* Your new primary color */
}

.popover-body {
  padding: 12px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(240, 240, 240);
}
.fira-code {
  font-family: "Fira Code", serif;
}
.bs-popover-top > .popover-arrow::after {
  border-top-color: #f95031;
}
.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #f95031;
}
.bs-popover-end > .popover-arrow::after {
  border-right-color: #f95031;
}
.bs-popover-start > .popover-arrow::after {
  border-left-color: #f95031;
}
.btn-ro-primary {
  background: #f3c130 !important;
  border-color: #f3c130 !important;
}
.btn-ro-primary:hover {
  background: #dbb238 !important;
  border-color: #f3c130 !important;
  color: white !important;
}

.ant-message {
  z-index: 1100 !important;
}
.cdk-global-overlay-wrapper {
  z-index: 1100 !important;
}
.cdk-overlay-container {
  z-index: 1100 !important;
}

.ant-btn-primary {
  background: #f3c130 !important;
  border-color: #f3c130 !important;
}
.ant-btn-primary:hover {
  background: #f3c130 !important;
  border-color: #f3c130 !important;
  color: white !important;
}
.ant-btn-primary:disabled {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.ant-btn-primary:disabled:hover {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: #999 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff4f2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f95031 !important;
  text-shadow: 0 0 0.25px #f95031 !important;
}
.ant-input-affix-wrapper-lg {
  font-size: 14px !important;
}

.ant-table-thead > tr > th {
  background: #f4f4f4 !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: transparent !important;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f95031 !important;
}
.ant-radio-inner::after {
  background-color: #f95031 !important;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #f9503112 !important;
}
.ant-radio-checked::after {
  border: 1px solid #f95031 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f95031 !important;
}
.ant-modal-content {
  border-radius: 8px !important;
}
.ant-card {
  border-radius: 8px !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #34a770 !important;
  border-color: #34a770 !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #abaeb0 !important;
}
.ant-tabs-ink-bar {
  background: #f95031 !important;
}
.text-bold {
  font-weight: 600;
}
.text-small {
  font-size: 10px;
}
.text-green {
  color: #34a770;
}
.text-danger {
  color: red;
}
.text-yellow {
  color: #f3c130;
}
.text-blue {
  color: #1890ff;
}
.text-grey {
  color: #828282;
}
.text-light {
  color: #00000040;
}

.border-dash {
  border: 2px dashed rgb(213, 213, 213);
  border-left: none;
  border-bottom: none;
  border-right: none;
}
.card-header {
  //border-bottom: none !important;
  background-color: white !important;
  font-weight: 600;
  font-size: 20px;
}

.card-footer {
  border-top: none !important;
  background-color: white !important;
}

.card {
  border: none;
  border-radius: 6px;
}

.btn-primary {
  color: $primaryColor !important;
}
.btn-secondary {
  color: $secondaryColor !important;
}

.btn-link {
  color: $primaryColor !important;
}

.input-group input {
  border-right: none !important;
}

.input-group-text {
  background-color: white !important;
}

.btn-outline-primary {
  &:hover {
    color: white !important;
  }
}
.form-check-input:checked {
  background-color: #f95031;
  border-color: #dc3d21;
}
.tooltip {
  --bs-tooltip-bg: white !important;
  color: #333333;
}

.tooltip-inner {
  background-color: white !important;
  color: #333333;
  box-shadow: 4px 8px #c1c1c1;
  background: white !important;
}

.md-drppicker .ranges ul li button.active {
  background-color: #f95031 !important;
  color: #fff;
}

.md-drppicker .btn {
  background-color: #f95031 !important;
  box-shadow: none !important;
}
.md-drppicker .btn.btn-default {
  background-color: rgba(255, 255, 255, 0) !important;
}
.md-drppicker td.active,
.md-drppicker td.active:hover {
  //background-color: rgba(251, 188, 5, 0.75) !important;
  background-color: #f95031 !important;
}
.md-drppicker td.in-range {
  background-color: #ffb1a4 !important;
}

.md-drppicker.shown.drops-down-left {
  transform-origin: 100% 0;
  top: auto;
  left: 509px !important;
  right: auto;
}

.md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
  top: auto;
  left: 509px !important;
  right: auto;
}

.md-drppicker.shown.drops-down-center {
  transform-origin: 100% 0;
  top: auto;
  left: -509px !important;
  right: auto;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: transparent !important;
  border: none !important;
}

.ant-input {
  border-radius: 8px !important;
}

.ant-input-affix-wrapper {
  border-radius: 8px !important;
}

.ant-input-group-addon:first-child {
  border-radius: 8px 0 0 8px !important;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  flex-direction: column;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

#dropdownfilterorder::after {
  display: none;
}
#dropdownstatus:hover {
  background-color: #ffcbc2;
}

#dropdownstatus::after {
  display: none;
}

a {
  text-decoration: none;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.sub-title {
  font-weight: 600;
  font-size: 18px;
}

.btn-link {
  text-decoration: none;
  font-weight: 500;
}

// CUSTOM
.popover-header {
  background: white !important;
  border: none;
}
.popover {
  /* Set the desired popover background color */
  background-color: #f1f1f1;
}

.popover.top > .arrow:after {
  border-bottom-color: #f95031;
}

.divider {
  background: #e2e2e2;
  height: 1px;
}

.fixed-top {
  z-index: 999 !important;
}

.modal-lg {
  --bs-modal-width: 700px !important;
}
.md-drppicker .ranges ul li button.active {
  background-color: #ffffff !important;
  color: #f95031 !important;
}
.md-drppicker.ltr .calendar.left .calendar-table {
  background-color: #f8f8f8 !important;
}
.md-drppicker.ltr .calendar.right .calendar-table {
  background-color: #f8f8f8 !important;
}
table .drp-animate {
  background-color: transparent !important;
}
.md-drppicker th {
  background-color: #f8f8f8 !important;
  color: #2f2f2f !important;
  font-size: 12px;
}
.md-drppicker td {
  opacity: 100 !important;
}
.md-drppicker td.off,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date,
.md-drppicker td.off.end-date {
  background-color: transparent !important;
  border-color: transparent;
  color: #999;
}
.md-drppicker td.start-date {
  border-radius: 7px 0 0 7px !important;
  background-color: #f95031 !important;
}
.md-drppicker td.end-date.in-range {
  border-radius: 0 7px 7px 0 !important;
  background-color: #f95031 !important;
}
.md-drppicker td.in-range {
  background-color: #ffece9 !important;
}
.md-drppicker .btn {
  border-radius: 6px !important;
}
.md-drppicker .btn.btn-default {
  color: #f95031 !important;
  margin-right: 10px;
}
.ant-progress-bg {
  background-color: #34a853 !important;
}
.ant-card-body {
  padding: 0px !important;
  width: 100% !important;
}
.ant-btn-dangerous.ant-btn-primary {
  background-color: #f95031 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #f95031 !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background: #828282 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #f95031 !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #828282 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f95031 !important;
  font-weight: 600 !important;
}

.dropdown-toggle::after {
  display: none !important;
}
.ant-btn:hover {
  color: #3b3b3b !important;
  border-color: #2f2f2f !important;
}
.ant-btn:focus {
  color: #3b3b3b !important;
  border-color: #2f2f2f !important;
}
.ant-btn-dangerous:focus,
.ant-btn-dangerous:hover {
  color: #fff !important;
  border-color: #f95031 !important;
  background: #f95031 !important;
}
.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  color: #fff !important;
  border-color: #ff7875 !important;
  background: #ff7875 !important;
}
@media screen and (max-width: 1680px) and (min-width: 1601px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: auto;
    left: 190px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -509px !important;
    right: auto;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1116px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: auto;
    left: 300px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -509px !important;
    right: auto;
  }
}
@media screen and (max-width: 1115px) and (min-width: 1061px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: auto;
    left: 160px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -509px !important;
    right: auto;
  }
}
@media screen and (max-width: 1060px) and (min-width: 1025px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: auto;
    left: 148px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -509px !important;
    right: auto;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: 213px !important;
    left: 167px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -509px !important;
    right: auto;
  }
  .layer[_ngcontent-ng-c1704051524] {
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 999;
  }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  body {
    font-family: "Poppins";
    background-color: #f8f9fa !important;
    font-size: 14px;
  }
  .layer[_ngcontent-ng-c1704051524] {
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 999;
  }
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: auto;
    left: 70px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -475px !important;
    right: auto;
  }
}

@media screen and (max-width: 500px) {
  .md-drppicker.shown.drops-down-left {
    transform-origin: 100% 0;
    top: auto;
    left: 509px !important;
    right: auto;
  }

  .md-drppicker.drops-down-left.ltr.double.show-ranges.shown {
    top: 75px !important;
    left: 80px !important;
    right: auto;
  }

  .md-drppicker.shown.drops-down-center {
    transform-origin: 100% 0;
    top: auto;
    left: -100px !important;
    right: auto;
    font-size: 10px;
  }
}
